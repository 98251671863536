.module.boxModalHeaderWithContent {
    width: 100%;
    .bmhwc-wrapper {
        > .header {
            margin-bottom: 1rem;
            .header-wrapper {
            }
        }
        > .content {
            .content-wrapper {
                max-height: 300px;
                overflow-y: auto;
                overflow-x: hidden;
                //padding-bottom: 3px;
            }
        }
    }
}
