.module.labelInput {
    .label-input-wrapper {
        > label {
            font-weight: bold;
            @extend .font-header;
        }
        span {
            color: $colorLabelInputRequire;
            font-weight: bold;
        }
    }
}
