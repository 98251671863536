.customTab {
  .item {
    font-size: 2em;
  }

  .red.item {
    border-color: #db2828 !important;
    color: #db2828 !important;
  }

  .ui.segment {
    padding: 0px;
    border: 0px;
    box-shadow: none;
  }
}
