.module.viewChildArea {
    width: 100%;
    height: 100%;
    padding: 1rem;
    @extend .noselect;
    .vsa-wrapper {
        width: 100%;
        height: 100%;
        // display: flex;
        // flex-direction: column;
        .vsa-form {
            // flex: 0 0 auto;
            width: 100%;
            margin-bottom: 1rem;
            .vsa-f-wrapper {
                width: 100%;
            }
        }
        .vsa-map {
            // flex: 1;
            // overflow: hidden;
            width: 100%;
            padding: 0rem 10rem;
            margin-bottom: 1rem;
            .map-wrapper {
                width: 100%;
                height: 100%;
                .map-image {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                    img {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        z-index: 1;
                    }
                    .location-svg {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;
                        //cursor: crosshair;
                        &.disabled {
                            cursor: unset;
                        }
                        .l-svg-body {
                            > {
                                width: 100%;
                                height: 100%;
                                fill: #fff0;
                            }
                        }
                        .area-style {
                            fill: rgba(97, 97, 97, 0.72);
                            transition: fill 0.5s;
                            &:hover {
                                fill: #ccc;
                            }
                        }
                        .area-pointer {
                            fill: #0f0;
                            cursor: pointer;
                        }
                    }
                }
            }
            .vsa-m-wrapper {
                width: 100%;
                // height: 100%;
                .map-wrapper-nolocation {
                    width: 100%;
                    height: 100%;
                    background-color: #ccc;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .vsa-action {
            text-align: center;
        }
    }
}
