.module.sensorRuleForm {
  .sensorRuleAction {
    min-height: calc(80vh - 10em) !important;
  }
}

.module.holidayDateTime {
  min-height: calc(70vh - 10em) !important;
}

.module.scheduleControlScene {
  min-height: calc(60vh - 10em) !important;
}

.module.actionResourcesECR {
  min-height: calc(80vh - 10em) !important;
}
