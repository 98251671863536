$colorTextOnPage: #fff;
$colorBGForm: #ffffff3b;

.module.viewLogin {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // background-image: url("/images/image-forest_fog_trees.jpg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;

    // /* Mobile*/
    // @media only screen and (max-width: $maxWidthMobile) {
    //     flex-direction: column;
    // }

    // p,
    // h1,
    // h3,
    // label {
    //     color: $colorTextOnPage;
    // }

    // p {
    //     @extend .font-body;
    // }

    .vl-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .vl-wrapper {
        flex: 0 0 auto;
        width: 30%;
        padding: 5rem 3rem;
        background-color: $colorBGForm;
        z-index: 2;
        /* Mobile*/
        @media only screen and (max-width: $maxWidthMobile) {
            width: 90%;
        }

        label,
        p {
            color: $colorTextOnPage !important;
            @extend .font-header;
            @extend .noselect;
        }

        .text-header-login {
            text-align: center;
            color: $colorTextOnPage;
            // margin-bottom: 2rem;
        }

        .vl-form {
            margin-top: 2rem;
        }

        .item-login {
            .il-form {
            }
            .il-action {
                margin-top: 1rem;
                text-align: center;
            }

            .loginButton {
                // margin-top: 2rem;
                .ui.button {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    width: 100%;
                    // margin-bottom: 1rem;
                    margin-right: 0;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .box-for-got-password {
                width: 100%;
                text-align: right;
                p {
                    display: inline;
                    cursor: pointer;
                    // cursor: not-allowed;
                }
            }
        }
    }
}
