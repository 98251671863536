.module.switchLanguage {
    .sl-wrapper {
        display: flex;
        .sl-item {
            padding: 0 5px;
            cursor: pointer;
            h3 {
                color: var(--color-text-bg);
            }
            &:not(:last-child) {
                border-right: 3px solid var(--color-border);
            }
            &.active {
                pointer-events: none;
                h3 {
                    color: var(--color-primary);
                }
            }
        }
    }
}
