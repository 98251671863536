.module.customModal {
    // background-color: $colorBGModal !important;
    .modal-header {
        border: none;
        padding-bottom: 0 !important;
        // background-color: $colorBGModal !important;
        .m-h-wrapper {
            // @extend .f-body-header;
            color: var(--color-text);
        }
    }
    .modal-content {
        // background-color: $colorBGModal !important;
        padding: 3rem;
        .m-c-wrapper {
            @extend .font-body;
            color: var(--color-text);
        }
    }
    .modal-action {
        border: none;
        //padding-top: 0 !important;
        // background-color: $colorBGModal !important;
        .m-a-wrapper {
        }
    }
}
