$sizeGap: 10px; //1rem = 10
$heightMenu: 60px;
$heightFooter: 60px;
$widthMenuLeft: 150px;

$borderRadiusBox: 10px;
:root {
    --size-gap: #{$sizeGap};
    --height-header: #{$heightMenu};
    --gap-header: #{$sizeGap + $heightMenu};
    --height-footer: #{$heightMenu};
    --gap-footer: #{$sizeGap + $heightFooter};
}

$maxWidthMobile: 767px;
