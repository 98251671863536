.ui.button {
    // @extend .font-button;
    // min-width: 100px;
    font-size: 1.6rem;
}
.ui.small.button {
    font-size: 1.4rem;
}
.ui.tiny.button {
    font-size: 1.2rem;
}
.ui.mini.button {
    font-size: 1rem;
}
