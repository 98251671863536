.module.viewTable {
    .view-table-wrapper {
  /*       .hkcGBu {
            @extend .font-body;
            font-weight: bold;
        } */
        .sc-dIsUp {
            @extend .font-body;
            font-weight: bold;
        }
        .rdt_TableCell,
        .rdt_Pagination {
            @extend .font-body;
        }

        .btusjc {
            padding-right: 30px;
        }

        //New
        .vt-custom-pagination {
            width: 100%;
            text-align: center;
            margin-bottom: 1rem;
            a {
                @extend .font-body;
                box-shadow: none;
            }
        }
        // .rdt_TableCell {
        //     width: 100%;
        //     .row-input {
        //         padding: 10px 0;
        //         width: 100%;
        //     }
        // }
        .vt-loading {
            padding: 2rem;
        }
        .vt-no-data {
            padding: 2rem;
            font-weight: bold;
            p {
                @extend .font-header;
            }
        }

        .sc-iwajpm {
            background-color: rgba(62, 144, 255, 0.15);
            //padding: 1em;
        }
    }
}
