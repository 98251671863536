$colorPrimary: #ff7c80;
$colorSecondary: #a6a6a6;
$colorBorder: #888888;
$colorText: #000;
$colorTextOnBG: #fff;

$colorSidebarBg: #000;
// $colorSidebarItemBg: #fff;
// $colorSidebarItemBgActive: #fff;
// $colorSidebarItemText: #fff;
// $colorSidebarItemTextActive: #fff;

$colorBoxBg: #ccc;
$colorBoxText: #000;

$colorLabelInputRequire: #f00;

:root {
    --color-primary: #{$colorPrimary};
    --color-secondary: #{$colorSecondary};
    --color-border: #{$colorBorder};
    --color-text: #{$colorText};
    --color-text-bg: #{$colorTextOnBG};
}
