@import "color";
@import "config";
@import "font";
@import "common";
@import "button";

// @import "~semantic-ui-css/semantic.min.css";

@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
.rdw-editor-main {
  border: 1px solid #f1f1f1;
}
.public-DraftStyleDefault-block {
  span::selection {
      background-color: rgba(100, 100, 100, 0.4) !important;
      color: rgba(0, 0, 0, 0.87) !important;
  }
}

//Common
@import "./common/customModal.scss";
@import "./common/viewImage.scss";
@import "./common/itemErrorFromAPI.scss";
@import "./common/sidebar.scss";
@import "./common/switchLanguage.scss";
@import "./common/boxAdvancedSearch.scss";
@import "./common/viewTable.scss";
@import "./common/viewTablePaging.scss";
@import "./common/viewNoResult.scss";
@import "./common/labelInput.scss";
@import "./common/boxModalHeaderWithContent.scss";
@import "./common/boxModalForTimeSlots.scss";
@import "./common/aboutModal.scss";
@import "./common/minHeightModal.scss";
@import "./common/modalSyncInfo.scss";
@import "./common/customTab.scss";
@import "./common/inputSelectIntegration.scss";

//Layout
@import "./layout/layoutBlank.scss";
@import "./layout/layoutWithSidebarHeaderFooter.scss";

//Common
@import "./common/viewSelectArea.scss";
@import "./common/viewChildArea.scss";

//Auth
@import "./auth/viewLogin.scss";
@import "./auth/viewLoginBypass.scss";
@import "./auth/viewForgotPassword.scss";
@import "./auth/viewResetPassword.scss";

// multiple checkbox container
@import "./common/multipleCheckboxContainer.scss";

// Department
@import "./department/inputSelectDepartment.scss";

// User
@import "./user/modalUserImport.scss";
@import "./user/formImportExcel.scss";