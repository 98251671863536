.module.viewNoResult {
    width: 100%;
    padding: var(--size-gap);
    .vnr-wrapper {
        text-align: center;
        h3 {
            color: var(--color-text);
        }
    }
}
