.module.formImportExcel {
    .fie-box-formatcode {
        width: 100%;
        display: flex;
        align-items: flex-end;
        .box-input {
            flex: 1;
            margin-right: 1rem;
        }
        .box-action {
            flex: 0 0 auto;
        }
    }
}
