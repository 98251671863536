.module .modalUserImport {
  .box-search {
    margin-bottom: 2rem;
    .ui.icon.input > i.circular.icon {
      top: 0.7em;
      right: 0.5em;
    }
  }

  .modal-import-user-segment {
    min-height: calc(60vh - 10em) !important;

    .ui.segment {
      max-height: calc(60vh - 10em) !important;
      padding: 1.5em 1.5em 1.5em 1.5em;
    }

    .user-data-list {
      overflow-y: auto;
      overflow-x: hidden;
      min-height: calc(60vh - 13em);
      max-height: calc(60vh - 13em);

      &.no-data {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
