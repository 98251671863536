.module.viewTablePaging {
    .vt-custom-pagination {
        // height: 50px;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .vt-c-p-rows {
            flex: 0 0 auto;
            margin-right: 2rem;
            .vt-c-p-rows-wrapper {
                display: flex;
                align-items: center;
                > * {
                    &:first-child {
                        flex: 0 0 auto;
                        margin-right: 0.5rem;
                    }
                    &:last-child {
                        width: 70px;
                    }
                }
            }
        }
        .vt-c-p-of {
            flex: 0 0 auto;
            margin-right: 1rem;
            .vt-c-p-of-wrapper {
            }
        }
        .vt-c-p-page {
            flex: 0 0 auto;
            .ui.pagination.menu {
                // min-height: unset !important;
                border: none;
                box-shadow: none;
                .item {
                    &::before {
                        display: none;
                    }
                    border: none;
                    box-shadow: none;
                    // padding: 0.5rem;
                    // height: 40px;
                    // width: 40px;
                    @extend .font-body;
                }
            }
        }
    }
}
