//***
body,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
.ui.input,
.ui.form,
.ui.form textarea,
.ui.input input,
.ui.checkbox,
.ui.dropdown,
button,
div,
span,
.ui.form .field > label,
.ui[class*="left icon"].input > input,
select {
  font-family: "rsu" !important;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

input {
  line-height: 1 !important;
}

// .ui.button {
//   &:last-child {
//     margin-right: 0 !important;
//   }
// }

div,
p,
b,
input,
.ui.fluid,
.ui.form,
.ui.checkbox,
.ui.dropdown .menu > .item {
  @extend .font-body;
}
.ui.form .field .prompt.label {
  @extend .font-body-imp;
}

// label {
//   font-weight: bold;
//   @extend .font-header;
// }

.noselect {
  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  -khtml-user-select: none !important;
  /* Konqueror HTML */
  -moz-user-select: none !important;
  /* Firefox */
  -ms-user-select: none !important;
  /* Internet Explorer/Edge */
  user-select: none !important;
  /* Non-prefixed version, currently
supported by Chrome and Opera */
}

* {
  // @extend .noselect;
  box-sizing: border-box !important;
}

@for $i from 1 through 30 {
  .text-overflow-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

//Grid
.grid-half-padding {
  > .row {
    &:not(:last-child) {
      padding-bottom: 0 !important;
    }
  }
  > .column:not(.row) {
    &:not(:last-child) {
      padding-bottom: 0 !important;
    }
  }
}

// .ui.steps {
//   padding: 0;
// }
// .ui.steps {
//   cursor: pointer;
// }
// .ui.steps .step.active,
// .ui.steps a.active.step:hover {
//   cursor: pointer;
//   background: #d2e8ff;
// }
// .ui.steps .title {
//   @extend .font-header;
// }

//S

h1.ui.header {
  font-size: 3rem !important;
}

h2.ui.header {
  font-size: 2.5rem !important;
}

h3.ui.header {
  font-size: 2rem !important;
}

h4.ui.header {
  font-size: 1.5rem !important;
}

h5.ui.header {
  font-size: 1rem !important;
}

h6.ui.header {
  font-size: 0.5rem !important;
}

.form.header {
  font-size: 3rem !important;
  margin-bottom: 1.5rem !important;
}

.view-header-button.ui.dividing.header {
  padding-bottom: 0.4em;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.view-small-divider {
  pointer-events: none !important;
}

.view-h3-header-button {
  font-size: 2rem;
}

.ui.segment.color-segment {
  padding: 0.7em 0.7em;
}

.ui.segment.group-segment-input {
  padding: 2em 3em 4em 3em !important;
  border: 1px solid rgba(34,36,38,.15) !important;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;
}

.ui.grid > .row.remove-bottom-padding {
  padding-bottom: 0 !important;
}

.box-image-view-info {
  width: 135px;
  height: 189px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-height: 100%;
  }
}

//Table
.rdt_TableBody {
  overflow-y: auto;
}
.box-action-table {
  width: 100%;
  overflow: hidden;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  > * {
    margin-bottom: 0.25em !important;
  }
}
// .kyEwlR,
.sc-egiSv {
  font-weight: bold !important;
}

.scrolling.dimmable > .dimmer {
  justify-content: center;
}

// striped table color
/* .ui.striped.table tbody tr:nth-child(2n), .ui.striped.table>tr:nth-child(2n) {
  background-color:#cce2ff !important;
} */

.table-button {
  .ui.button {
    padding: 0.78571429em 0.78571429em 0.78571429em;
  }
}

.popup-blank {
  padding: 0 !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border: none !important;
}

.view-container {
  .ui.table {
    word-break: break-all;
  }
}

.module.boxTimeSlot {
  width: 100%;
  .b-t-slot {
    margin: 0 0 1rem 0;
    border: 1px solid $colorBorder;
    border-radius: 1rem;
    padding: 1rem;
    max-height: 150px;
    overflow: auto;
    .item-time-slot {
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $colorBorder;
      padding: 0.5rem 0;
      &:last-child {
        border-bottom: none;
      }
      .its-text {
        flex: 1;
        overflow: hidden;
        .its-text-wrapper {
        }
      }
      .its-action {
        flex: 0 0 auto;
        .its-action-wrapper {
        }
      }
    }
  }
  .b-t-action {
    text-align: center;
  }
}

// view min-height 3 content
.minThree {
  min-height: 4em;
  max-height: 6em;
  overflow-y: auto;
}

// view for long content
.longContentMin {
  height: 300px;
  overflow-y: auto;
}

.ui.disabled.input {
  color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.ui.form .disabled.field {
  color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
