.module .modalSyncInfo {
    .box-search {
        margin-bottom: 2rem;
        .ui.icon.input > i.circular.icon {
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;
        }
    }

    .modal-import-data-segment {
        min-height: calc(60vh - 10em) !important;

        .ui.segment {
            max-height: calc(60vh - 10em) !important;
            padding: 1.5em 1.5em 1.5em 1.5em;
        }

        .data-list {
            overflow-y: auto;
            overflow-x: hidden;
            min-height: calc(60vh - 13em);
            max-height: calc(60vh - 13em);
            height: calc(60vh - 13em);

            &.no-data {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .module.boxModalHeaderWithContent {
        .bmhwc-wrapper {
            > .header {
                margin-bottom: 1rem;
            }
            > .content {
                .content-wrapper {
                    max-height: unset !important;
                    overflow: unset !important;
                }
            }
        }
    }

    .ui.loader {
        color: rgba(0, 0, 0, 0.9) !important;
        &:before {
            border-color: rgba(0, 0, 0, 0.15);
        }
        &:after {
            border-color: #000 transparent transparent;
        }
    }

    .msi-search {
        margin-bottom: 2rem;
        i {
            top: 50% !important;
            transform: translateY(-50%);
            //right: 1rem !important;
        }
    }

    .msi-content {
        .mis-c-list {
            height: 260px;
            margin-bottom: 1rem;
            overflow: auto;
            .ui.segment {
                height: 100%;
            }
            .data-list {
                height: 100%;
                overflow: auto;
                &.loading,
                &.no-data {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .mis-c-pagination {
            .mis-c-p-loading {
                .module.viewLoading {
                    justify-content: flex-end;
                }
            }
        }
    }
}
