.module.multipleCheckboxContainer {
    width: 100%;

    .ui.segment.group-segment {
        padding: 2em 3em 2em 3em;
        border: 1px solid rgba(34,36,38,.15);
        box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    }
    .checkbox-wrapper {
        min-height: 10em;
        max-height: 10em;
    }
    .checkbox-wrapper-holiday {
        
    }
    .checkbox-wrapper,
    .checkbox-wrapper-holiday {
        overflow-y: auto;
        overflow-x: hidden;
        > * {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &.search {
            background-color: #fff;
            min-height: 130px;
            max-height: 130px;
            padding: 1rem;
            border-radius: 0.28571429rem;
        }
    }
}
