$colorSearchBG: #f1f1f1;
$borderRadius: 5px;
.module.boxAdvancedSearch {
    .bas-wrapper {
        margin-top: 30px;
        margin-bottom: 10px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;

        &.searchHidden {
            display: none;
        }

        &.search {
            overflow: unset;
        }

        .body-search {
            padding: 1.5em;
            background-color: $colorSearchBG;
            border-radius: $borderRadius;
            //margin-top: var(--size-gap);
            .ui.selection.dropdown {
                padding: 1em 2.1em 0.78571429em 1em;
                min-height: 2.9em;
            }
        }

        #searchButton.row {
            padding-top: 1.5em;
        }
    }
}
