$colorActive: #7b7b7b;
$colorText: #fff;
.module.sidebar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: 1px solid $colorBorder;
    background-color: $colorSidebarBg;
    .sidebar-header {
        flex: 0 0 auto;
        border-bottom: 1px solid $colorBorder;
        padding: var(--size-gap);
        .header-image {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: var(--size-gap);
            .image-wrapper {
                width: 160px;
            }
        }
        .header-language {
            display: flex;
            justify-content: center;
        }
    }
    .sidebar-body {
        flex: 1;
        overflow: hidden;
        .body-wrapper {
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            // padding: 0 $sizeGap;
            .menu-wrapper {
                p,
                i {
                    // color: $colorText;
                    color: $colorActive;
                    @extend .font-header;
                }
                .menu-item {
                    margin-bottom: 5px;
                    .item-wrapper {
                        padding: $sizeGap;
                        cursor: pointer;
                        display: flex;
                        &:hover {
                            // background-color: $colorActive;
                            p,
                            i {
                                color: $colorText;
                            }
                        }
                        .i-text {
                            flex: 1;
                            overflow: hidden;
                        }
                        .i-icon {
                            flex: 0 0 auto;
                        }
                    }
                }
                .active {
                    pointer-events: none;
                    background-color: $colorActive;
                    .item-wrapper {
                        p,
                        i {
                            color: $colorText;
                        }
                        .i-icon {
                            i {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                .active-panel {
                    .item-wrapper {
                        p,
                        i {
                            color: $colorText;
                        }
                        .i-icon {
                            i {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                .menu-panel {
                    padding-left: 10px;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.2s ease-out;
                }
            }
        }
    }
    .sidebar-footer {
        flex: 0 0 auto;
        padding: var(--size-gap);
        border-top: 1px solid $colorBorder;

        .footer-wrapper {
            width: 100%;
            .f-user {
                width: 100%;
                // margin-bottom: var(--size-gap);
                .user-wrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // border: 1px solid $colorBorder;
                    // padding: 10px;
                    .u-image {
                        width: 40px;
                        .image-wrapper {
                            width: 100%;
                            .module.viewImage > .create-body-image.circular {
                                border: 1px solid $colorBorder;
                                background-color: $colorText;
                            }
                        }
                    }
                    .u-data {
                        flex: 1;
                        overflow: hidden;
                        color: $colorText;
                        margin-left: 10px;
                        p {
                            @extend .font-header;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
            // .f-action {
            //     // border-top: 1px solid $colorBorder;
            //
            // }
        }
    }
    .sidebar-action {
        flex: 0 0 auto;
        text-align: center;
        padding: var(--size-gap);
        // border-top: 1px solid $colorBorder;
        Button
        {
            width: 100%;
        }
    }
}
