@mixin font-size($size) {
    font-size: $size * (1rem);
}
@mixin font-size-important($size) {
    font-size: $size * (1rem) !important;
}

.font-body {
    @include font-size(1.6);
}
.font-body-imp {
    @include font-size-important(1.6);
}

.font-header {
    @include font-size(1.8);
}

// .font-button {
//     @include font-size(1.6);
// }
// .font-button-imp {
//     @include font-size-important(1.6);
// }

h1 {
    @include font-size(4);
}
h2 {
    @include font-size(3);
}
h3 {
    @include font-size(2.2);
}
