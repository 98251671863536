$colorTextOnPage: #fff;
$colorBGForm: #ffffff3b;

.module.viewLoginBypass {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // background-image: url("/assets/images/image-forest_fog_trees.jpg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    .vlb-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .vlb-wrapper {
        z-index: 2;
        flex: 0 0 auto;
        width: 30%;
        padding: 5rem 3rem;
        background-color: $colorBGForm;
        text-align: center;
        /* Mobile*/
        @media only screen and (max-width: $maxWidthMobile) {
            width: 90%;
        }

        h1 {
            color: $colorTextOnPage;
        }
    }
}
