.module.viewImage {
    width: 100%;
    // float: left;
    // display: inline-block;
    // position: relative;

    > .create-body-image {
        width: 100%;
        float: left;
        display: inline-block;
        position: relative;

        &::before {
            content: "";
            display: block;
            padding-top: 56.25%;
            /* initial ratio of 16:9 9=56.25%*/
        }

        &.circular {
            border-radius: 50%;
            overflow: hidden;
            // background-color: $colorBGNoImage;
            // border: 1px solid $colorBGNoImage;
            &::before {
                content: "";
                display: block;
                padding-top: 100%;
                /* initial ratio of 1:1*/
            }
        }

        &.square {
            &::before {
                content: "";
                display: block;
                padding-top: 100%;
                /* initial ratio of 1:1*/
            }
        }

        > .image-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            .box-image {
                width: 100%;
                height: 100%;

                .render-image {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    &.ratio {
                        > img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    &.ratio-width {
                        > img {
                            max-width: 100%;
                            height: auto;
                            object-fit: cover;
                        }
                    }

                    &.ratio-height {
                        > img {
                            width: auto;
                            max-height: 100%;
                            object-fit: cover;
                        }
                    }

                    &.noratio {
                        > img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}
