.module.inputFile {
    .if-wrapper {
        .box-input-ui {
            display: flex;
            .biu-input {
                flex: 1;
                overflow: hidden;
                margin-right: 1rem;
            }
            .biu-action {
                flex: 0 0 auto;
            }
        }
        .box-input-file {
            max-height: 0;
            overflow: hidden;
            margin-bottom: 0;
        }
    }
}
