$colorSidebarBG: #1b1c1d;
$colorHeaderBG: #fff;

$heightHeader: 70px;
$heightFooter: 60px;
.module.layoutWithSidebarHeaderFooter {
    .lwshf-sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 250px;
        // background: $colorSidebarBG;
        overflow-x: hidden;
        z-index: 2;
    }

    .lwshf-body {
        margin-left: 250px;
        min-width: 550px;
        // max-width: 1150px;
        padding-top: var(--size-gap);
        z-index: 2;

        // height: 100vh;
        // height: calc(var(--vh, 1vh) * 100);

        .lwshf-menu-top {
            display: flex;
            align-items: center;
            height: $heightHeader;
            margin-left: 250px;
            background-color: $colorHeaderBG;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        }

        .lwshf-menu-bottom {
            display: flex;
            align-items: center;
            height: $heightFooter;
            padding-left: 250px;
            background-color: $colorHeaderBG;
            z-index: 1;
            .menu-bottom-wrapper {
                width: 100%;
                text-align: center;
            }
        }
    }

    .add-header {
        padding-top: $heightHeader + $sizeGap + $sizeGap;
    }
    .add-footer {
        padding-bottom: $heightFooter + $sizeGap + 200px;
    }
}
