.module.viewSelectImageOrTakePhoto {
    .vsiotp-wrapper {
        // > * {
        //     margin-bottom: 1rem;
        //     &:last-child {
        //         margin-bottom: 0;
        //     }
        // }
        .box-btn-action {
            // margin-bottom: 0;
        }
        .box-input-file {
            max-height: 0;
            overflow: hidden;
            margin-bottom: 0;
        }
        .box-show-image {
            margin-top: 1rem;
            .image-render {
                // width: 135px;
                width: 100%;
                height: 189px;
                // background-color: #ccc;
                // border: 1px solid #ccc;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                img {
                    max-width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
.module.modalTakePhoto {
    width: 100%;
    .mtp-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .box-webcam {
            &.hide {
                max-width: 0;
                overflow: hidden;
            }
        }
    }
}
