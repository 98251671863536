.module.inputDatePicker {
    .hide-picker {
        height: 0 !important;
        width: 0 !important;
        overflow: hidden;
    }
    .input-display {
        input {
            cursor: pointer;
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    }
}
