$colorTextOnPage: #fff;
$colorBGForm: #ffffff3b;

.module.viewResetPassword {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    // background-image: url("/assets/images/image-forest_fog_trees.jpg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;

    // /* Mobile*/
    // @media only screen and (max-width: $maxWidthMobile) {
    //     flex-direction: column;
    // }

    // p,
    // h1,
    // h3,
    // label {
    //     color: $colorTextOnPage;
    // }

    // p {
    //     @extend .font-body;
    // }

    .vl-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .vl-wrapper {
        flex: 0 0 auto;
        width: 30%;
        padding: 5rem 3rem;
        background-color: $colorBGForm;
        z-index: 2;
        /* Mobile*/
        @media only screen and (max-width: $maxWidthMobile) {
            width: 90%;
        }

        label,
        p {
            color: $colorTextOnPage !important;
            @extend .font-header;
            @extend .noselect;
        }

        .text-header-login {
            text-align: center;
            color: $colorTextOnPage;
            // // margin-bottom: 2rem;
        }

        .vl-form {
            // margin-top: 2rem;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .vl-action {
            text-align: center;
        }
    }
}
