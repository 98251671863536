.module.inputSelectIntegration {
    .isd-wrapper {
        .box-input {
            cursor: pointer;
            .ui.input,
            .disabled.field {
                opacity: 1;
            }
        }
    }
}

.module.modalInputSelectIntegration {
    width: 100%;
    .misd-wrapper {
        width: 100%;
        .box-search {
            margin-bottom: 2rem;
            .ui.icon.input > i.circular.icon {
                top: 0.70em;
                right: 0.5em;
            }
        }
        .box-list {
            height: 250px;
            padding: 1rem;
            border-radius: 0.28571429rem;
            border: 1px solid rgba(34, 36, 38, 0.15);
            .bs-wrapper {
                height: 100%;
                overflow: auto;
                &.loading,
                &.no-data {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .ui.dimmer {
                        .ui.loader {
                            color: rgba(0, 0, 0, 0.9) !important;
                            &:before {
                                border-color: rgba(0, 0, 0, 0.15);
                            }
                            &:after {
                                border-color: #000 transparent transparent;
                            }
                        }
                    }
                }

                .bs-item {
                    // margin-bottom: 1rem;
                    // &:last-child {
                    //     margin-bottom: 0;
                    // }
                    .bs-i-wrapper {
                    }
                }
            }
        }
    }
}
