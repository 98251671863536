.module.viewTreeSelect {
    .vts-wrapper {
        .box-input {
            cursor: pointer;

            .ui.input,
            .disabled.field {
                opacity: 1;
                // border: 1px solid rgba(34, 36, 38, 0.3);
            }
        }
    }
}

.module.modalTreeSelect {
    width: 100%;
    .mts-wrapper {
        .box-list {
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: auto;
            .parent {
                // margin-bottom: 5px;
                // &:last-child {
                //     margin-bottom: 0;
                // }

                display: flex;
                .box-icon-expand {
                    flex: 0 0 auto;
                    width: 20px;
                    cursor: pointer;
                    i {
                        transition: transform 0.1s ease-in-out;
                    }
                    &.expanded {
                        i {
                            transform: rotate(90deg);
                        }
                    }
                    &.no-expanded {
                        cursor: unset;
                    }
                }
                .box-checkbox {
                    flex: 1;
                    overflow: hidden;
                    .b-c-wrapper {
                        width: 100%;
                        > .b-c-input {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            > * {
                                &:first-child {
                                    margin-right: 5px;
                                }
                            }
                            .image {
                                flex: 0 0 auto;
                                width: 20px;
                                height: 20px;
                                overflow: hidden;
                                margin-right: 5px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    vertical-align: unset !important;
                                }
                            }
                            .icon {
                                flex: 0 0 auto;
                                margin-right: 5px;
                                i {
                                    font-size: 16px;
                                    margin: 0 !important;
                                    line-height: 1 !important;
                                }
                            }
                            .text {
                                flex: 1;
                            }
                            // .empty {
                            //     flex: 1;
                            // }
                        }
                        > .children {
                            max-height: 0;
                            overflow: hidden;
                            transition: max-height 0.5s ease-out;
                        }
                    }

                    &.expanded {
                        > .b-c-wrapper {
                            > .children {
                                max-height: 500px;
                            }
                        }
                    }
                }
            }
        }
        .box-your-select {
            padding: 1rem 0;
        }
        .box-action {
            text-align: center;
        }
    }
}
